import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import LoadingOverlay from 'react-loading-overlay';

import { Bar, Doughnut, Line } from 'react-chartjs-2';

const Painel = () => {
 
  //Atualização
  const [ tipoPessoa , setTipoPessoa ] = useState([]);
  const [ caGrafico, setCaGrafico ] = useState(false);
  const [ caGraficoTipo, setCaGraficoTipo ] = useState(false);
  const [ caGraficoAnuidade, setCaGraficoAnuidade ] = useState(false);
  const [ caGraficoMes, setCaGraficoMes ] = useState(false);
  const [ caPainel, setCaPainel ] = useState(false);

  //Grafico Tipo
  const [ tipoLabel , setTipoLabel ] = useState([]);
  const [ tipoValor , setTipoValor ] = useState([]);
  const dataGraficoTipo = {
    labels: tipoLabel,
    datasets: [{
      data: tipoValor,
      label: 'Qtde de Cadastro por Tipo',
      backgroundColor: [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(169, 169, 169, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      hoverOffset: 10,
    }]
  };

  //Grafico das Regionais
  const [ regionalLabel , setRegionalLabel ] = useState([]);
  const [ regionalValor , setRegionalValor ] = useState([]);
  const dataGrafico = {
    labels: regionalLabel,
    datasets: [
        {
            label: 'Qtde de Cadastro por Regional',
            backgroundColor: [
              'rgba(169, 169, 169, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
            data: regionalValor
        }
    ]
};

//Grafico Anuidade Quantidade
const [ anuidadeLabel , setAnuidadeLabel ] = useState([]);
const [ anuidadeValor , setAnuidadeValor ] = useState([]);
const [ anuidadeQuantidade , setAnuidadeQuantidade ] = useState([]);
const dataGraficoAnuidade = {
  labels: anuidadeLabel,
  datasets: [{
    data: anuidadeQuantidade,
    fill: false,
    borderColor: 'green'
  }]
};

//Grafico Anuidade Valor
const dataGraficoAnuidadeValor = {
  labels: anuidadeLabel,
  datasets: [{
    data: anuidadeValor,
    backgroundColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
      'rgba(220, 20, 60, 1)',
      'rgba(127, 255, 0, 1)',
      'rgba(189, 183, 107, 1)',
      'rgba(255, 215, 0, 1)',
      'rgba(255, 255, 0, 1)',
      'rgba(128, 0, 128, 1)',
      'rgba(175, 238, 238, 1)',
      'rgba(0, 0, 128, 1)',
      'rgba(178, 34, 34, 1)',
    ],
    hoverOffset: 4
  }]
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      },
    ],
  },
}

const legenda = {
  display: false,
};

const optionsLeft = {
  legend: {
    display: true,
    position: "left"
  }
};

  useEffect(() => {
    painel();
    getGraficoRegional();
    getGraficoTipo();
    getGraficoAnuidade();
    getGraficoMes();
  }, []);

  function painel(){
    Api.get("painel/painelValor").then(rps => {
      setTipoPessoa(rps.data.obj);
    });
  }

  function getGraficoRegional() {
    setCaGrafico(true);
    Api.get("painel/graficoRegional").then(rps => {
      //***Inicio do valor Grafico Regional
      var formataRegionalTexto = [];
      var formataRegionalValor = [];

      rps.data.obj.map( item => {
        if (item.regional == null)
          formataRegionalTexto.push('Sem Regional');
        else
          formataRegionalTexto.push(item.regional);
          formataRegionalValor.push(item.total);
      })

      setRegionalLabel(formataRegionalTexto);
      setRegionalValor(formataRegionalValor);

      setCaGrafico(false);
    })
  }

  function getGraficoTipo() {
    setCaGraficoTipo(true);
        
      Api.get("painel/graficoTipo").then(rps => {
        //***Inicio do valor Grafico Programacao
        var formataTipoTexto = [];
        var formataTipoValor = [];
  
        rps.data.obj.map( item => {
            formataTipoTexto.push(item.tipo_cadastro);
            formataTipoValor.push(item.total);
        })
  
        setTipoLabel(formataTipoTexto);
        setTipoValor(formataTipoValor);
      
        setCaGraficoTipo(false);
      })
  }

  function getGraficoAnuidade() {
    setCaGraficoAnuidade(true);
        
      Api.get("painel/graficoAnuidade").then(rps => {
        //***Inicio do valor Grafico Anuidade
        var formataAnuidadeTexto = [];
        var formataAnuidadeQuantidade = [];
        var formataAnuidadeValor = [];
  
        rps.data.obj.map( item => {
            formataAnuidadeTexto.push(item.ano);
            formataAnuidadeQuantidade.push(item.qtde);
            formataAnuidadeValor.push(item.total);
        })
  
        setAnuidadeLabel(formataAnuidadeTexto);
        setAnuidadeQuantidade(formataAnuidadeQuantidade);
        setAnuidadeValor(formataAnuidadeValor);
      
        setCaGraficoAnuidade(false);
      })
  }

  function getGraficoMes() {
    setCaGraficoMes(true);
        
      Api.get("painel/graficoMes").then(rps => {
        //***Inicio do valor Grafico Anuidade
        var formataMesTexto = [];
        var formataMesQuantidade = [];
        var formataMesValor = [];
  
        rps.data.obj.map( item => {
            formataMesTexto.push(item.Mes);
            formataMesQuantidade.push(item.qtde);
            formataMesValor.push(item.valor);
        })
  
        setMesLabel(formataMesTexto);
        setMesQuantidade(formataMesQuantidade);
        setMesValor(formataMesValor);
      
        setCaGraficoMes(false);
      })
  }

  //Grafico Anuidade Quantidade
  const [ mesLabel , setMesLabel ] = useState([]);
  const [ mesValor , setMesValor ] = useState([]);
  const [ mesQuantidade , setMesQuantidade ] = useState([]);
  const dataGraficoQuantidadeMes = {
    labels: mesLabel,
    datasets: [{
      label: 'Quantidade por mês',
      data: mesQuantidade,
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      borderColor: 'red',
      borderWidth: 1
    },
  ]
  };

  const dataGraficoValorMes = {
    labels: mesLabel,
    datasets: [
    {
      label: 'Valor por mês',
      data: mesValor,
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      borderColor: 'blue',
      borderWidth: 1
    },
  ]
  };

    
    return (
        <>
        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-6 mb-2 mr-5">Painel de Controle</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-6 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}          
          </div>
        </div>
     
       
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
      {/*begin::Row*/}
      <div className="row">

        {tipoPessoa.map( item => { 
          return ( 
            <>
              {/*Inicio do Painel*/}
              <div class="col">
                <LoadingOverlay
                  active={caPainel}
                  spinner
                  text='Carregando...'
                >
                {/*begin::Tiles Widget 12*/}
                <div className="card card-custom gutter-b" style={{height: '120px'}}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="text-dark font-weight-bolder font-size-h2 mt-3">{item.total}</div>
                        <span className="text-success font-weight-bold font-size-lg mt-1">
                          {item.tipo_pessoa === "1" && 'Pessoa Física'}
                          {item.tipo_pessoa === "2" && 'Pessoa Física Exterior'}
                          {item.tipo_pessoa === "3" && 'Pessoa Jurídica'}
                          {item.tipo_pessoa === "4" && 'Pessoa Jurídica Exterior'}
                          {item.tipo_pessoa === "5" && 'Pessoa Honorário'}
                        </span>
                      </div>
                      <div className="col-md-4">
                        <span className="svg-icon svg-icon-6x svg-icon-success">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24" />
                              <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                              <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>
                {/*end::Tiles Widget 12*/}
              {/*Fim Painel*/}
              </LoadingOverlay>
            </div>
            </>
          )
        }
        )}

       
         
                
              </div>
              {/*end::Row*/}
              
              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                  <LoadingOverlay
                    active={caGraficoTipo}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Cadastro por Tipo</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Bar data={dataGraficoTipo} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>

                <div className="col-xl-6">
                <LoadingOverlay
                    active={caGrafico}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header h-auto border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Cadastro por Regional</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Bar data={dataGrafico} options={options} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                  <LoadingOverlay
                    active={caGraficoTipo}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Sócios por ano</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Line data={dataGraficoAnuidade} legend={legenda} options={options} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>

                <div className="col-xl-6">
                <LoadingOverlay
                    active={caGrafico}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header h-auto border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Recebimento por ano</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Doughnut data={dataGraficoAnuidadeValor} options={optionsLeft} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                  <LoadingOverlay
                    active={caGraficoTipo}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Quantidade de Anuidade em 2024</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Bar data={dataGraficoQuantidadeMes} options={options} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>

                <div className="col-xl-6">
                <LoadingOverlay
                    active={caGrafico}
                    spinner
                    text='Carregando...'
                  >
                  {/*begin::Charts Widget 2*/}
                  <div className="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
                    {/*begin::Header*/}
                    <div className="card-header h-auto border-0">
                      {/*begin::Title*/}
                      <div className="card-title py-5">
                        <h3 className="card-label">
                          <span className="d-block text-dark font-weight-bolder">Valor Total de Anuidade em 2024</span>
                        </h3>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Chart*/}
                        <Bar data={dataGraficoValorMes} options={options} />
                      {/*end::Chart*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Charts Widget 2*/}
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Row*/}


              {/*end::Dashboard*/}
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Entry*/}
        
        {/*end::Content*/}
        {/*begin::Footer*/}
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
          {/*begin::Container*/}
          <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
            {/*begin::Copyright*/}
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted font-weight-bold mr-2">2021©</span>
              <a href="#" target="_blank" className="text-dark-75 text-hover-primary">LAC</a>
            </div>
            {/*end::Copyright*/}
            {/*begin::Nav*/}
            <div className="nav nav-dark order-1 order-md-2">
              {/*Aqui Link e informação a direita */}
            </div>
            {/*end::Nav*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Footer*/}
        {/*end::Wrapper*/}
        {/*end::Page*/}
        {/*end::Main*/}      
        </>
    );

}

export default withRouter(Painel);                  