import React from 'react';
import { BrowserRouter as HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../layout';

import Swal from 'sweetalert2';

import Login from '../pages/login';
import Painel from '../pages/painel';
import Compras from '../pages/compras';
import Inscricoes from '../pages/inscricoes';
import Cadastro from '../pages/sistema/cadastro';
import Anuidade from '../pages/sistema/cadastro/anuidade';
import Regional from '../pages/sistema/regional';
import Evento from '../pages/sistema/evento';
import Palestra from '../pages/sistema/evento/palestra';
import EventoInscricoes from '../pages/sistema/evento/inscricoes';
import EventoFinanceiro from '../pages/sistema/evento/financeiro';
import Revista from '../pages/sistema/revista';
import Artigo from '../pages/sistema/artigo';
import RevistaFinanceiro from '../pages/sistema/revista/financeiro';

import Ranuidades from '../pages/sistema/relatorios/anuidades';
import Rhistorico from '../pages/sistema/relatorios/historico';
import Retiquetas from '../pages/sistema/relatorios/etiquetas';
import Raniversarios from '../pages/sistema/relatorios/aniversarios';
import Etiquetas from '../pages/sistema/relatorios/etiquetas/imprimir';
import Rregionais from '../pages/sistema/relatorios/regionais';
import Restudantes from '../pages/sistema/relatorios/estudantes';
import Rrenovacao from '../pages/sistema/relatorios/renovacao';

import Historico from '../pages/site/institucional/historico';
import Objetivos from '../pages/site/institucional/objetivos';
import Organizacional from '../pages/site/institucional/organizacional';
import Associacao from '../pages/site/institucional/associacao';
import Estatuto from '../pages/site/institucional/estatuto';
import Cds from '../pages/site/publicacoes/cds';
import Livros from '../pages/site/publicacoes/livros';
import Manuais from '../pages/site/publicacoes/manuais';
import Anuncios from '../pages/site/revista/anuncios';
import Normas from '../pages/site/revista/normas';
import RevistaStab from '../pages/site/revista/revistastab';
import Noticias from '../pages/site/noticias';
import GrupoLatino from '../pages/site/institucional/grupo_latino';

import Pais from '../pages/configuracoes/localizacao/pais';
import Regiao from '../pages/configuracoes/localizacao/regiao';
import Estado from '../pages/configuracoes/localizacao/estado';
import Cidade from '../pages/configuracoes/localizacao/cidade';

import Ano from '../pages/configuracoes/revista/ano';
import Cor from '../pages/configuracoes/revista/cor';
import Espaco from '../pages/configuracoes/revista/espaco';
import Mes from '../pages/configuracoes/revista/mes';
import NomeRevista from '../pages/configuracoes/revista/revista';

import TipoPessoa from '../pages/configuracoes/sistema/tipo_pessoa';
import TipoSocio from '../pages/configuracoes/sistema/tipo_socio';
import AreaAtuacao from '../pages/configuracoes/sistema/area_atuacao';
import Pesquisa from '../pages/configuracoes/sistema/pesquisa';
import FinCategoria from '../pages/configuracoes/sistema/fin_categoria';

// Verificar se o usuário está autenticado
const isAuthenticated = () => {
    if("hash" in localStorage){
        return true
    } else {
        Swal.fire(
            'Erro!',
            'Você não está logado, tente novamente.',
            'error'
        )
        return false
    }
};
// A função abaixo é para proteger uma rota
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );

const Routes = (props) => (
    <HashRouter>
        <Switch>
            { /* Rotas de Login não necessitam de Auth */}
            <Route exact path="/" component={Login}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/login/:usuario/:hash" component={Login}></Route>
            <Route exact path="/auth" component={Login}></Route>
            <AuthenticatedRoute exact path="/sistema/relatorios/etiquetas/imprimir" component={Etiquetas} />
            { /* ------------------------------------- */}
            <Layout>
                <AuthenticatedRoute exact path="/painel" component={Painel} />
                <AuthenticatedRoute exact path="/compras" component={Compras} />
                <AuthenticatedRoute exact path="/inscricoes" component={Inscricoes} />
                <AuthenticatedRoute exact path="/sistema/cadastro" component={Cadastro} />
                <AuthenticatedRoute exact path="/sistema/cadastro/anuidade" component={Anuidade} />
                <AuthenticatedRoute exact path="/sistema/regional" component={Regional} />
                <AuthenticatedRoute exact path="/sistema/evento" component={Evento} />
                <AuthenticatedRoute exact path="/sistema/evento/palestra" component={Palestra} />
                <AuthenticatedRoute exact path="/sistema/evento/inscricoes" component={EventoInscricoes} />
                <AuthenticatedRoute exact path="/sistema/evento/financeiro" component={EventoFinanceiro} />
                <AuthenticatedRoute exact path="/sistema/revista" component={Revista} />
                <AuthenticatedRoute exact path="/sistema/artigo" component={Artigo} />
                <AuthenticatedRoute exact path="/sistema/revista/financeiro" component={RevistaFinanceiro} />

                <AuthenticatedRoute exact path="/sistema/relatorios/anuidades" component={Ranuidades} />
                <AuthenticatedRoute exact path="/sistema/relatorios/etiquetas" component={Retiquetas} />
                <AuthenticatedRoute exact path="/sistema/relatorios/historico" component={Rhistorico} />
                <AuthenticatedRoute exact path="/sistema/relatorios/aniversarios" component={Raniversarios} />
                <AuthenticatedRoute exact path="/sistema/relatorios/regionais" component={Rregionais} />
                <AuthenticatedRoute exact path="/sistema/relatorios/estudantes" component={Restudantes} />
                <AuthenticatedRoute exact path="/sistema/relatorios/renovacao" component={Rrenovacao} />

                <AuthenticatedRoute exact path="/site/institucional/historico" component={Historico} />
                <AuthenticatedRoute exact path="/site/institucional/objetivos" component={Objetivos} />
                <AuthenticatedRoute exact path="/site/institucional/organizacional" component={Organizacional} />
                <AuthenticatedRoute exact path="/site/institucional/associacao" component={Associacao} />
                <AuthenticatedRoute exact path="/site/institucional/estatuto" component={Estatuto} />
                <AuthenticatedRoute exact path="/site/publicacoes/cds" component={Cds} />
                <AuthenticatedRoute exact path="/site/publicacoes/livros" component={Livros} />
                <AuthenticatedRoute exact path="/site/publicacoes/manuais" component={Manuais} />
                <AuthenticatedRoute exact path="/site/revista/anuncios" component={Anuncios} />
                <AuthenticatedRoute exact path="/site/revista/normas" component={Normas} />
                <AuthenticatedRoute exact path="/site/revista/revistastab" component={RevistaStab} />
                <AuthenticatedRoute exact path="/site/noticias" component={Noticias} />
                <AuthenticatedRoute exact path="/site/grupo_latino" component={GrupoLatino} />
                                
                <AuthenticatedRoute exact path="/configuracoes/localizacao/pais" component={Pais} />
                <AuthenticatedRoute exact path="/configuracoes/localizacao/regiao" component={Regiao} />
                <AuthenticatedRoute exact path="/configuracoes/localizacao/estado" component={Estado} />
                <AuthenticatedRoute exact path="/configuracoes/localizacao/cidade" component={Cidade} />

                <AuthenticatedRoute exact path="/configuracoes/revista/ano" component={Ano} />
                <AuthenticatedRoute exact path="/configuracoes/revista/cor" component={Cor} />
                <AuthenticatedRoute exact path="/configuracoes/revista/espaco" component={Espaco} />
                <AuthenticatedRoute exact path="/configuracoes/revista/mes" component={Mes} />
                <AuthenticatedRoute exact path="/configuracoes/revista/revista" component={NomeRevista} />

                <AuthenticatedRoute exact path="/configuracoes/sistema/tipopessoa" component={TipoPessoa} />
                <AuthenticatedRoute exact path="/configuracoes/sistema/tiposocio" component={TipoSocio} />
                <AuthenticatedRoute exact path="/configuracoes/sistema/areaatuacao" component={AreaAtuacao} />
                <AuthenticatedRoute exact path="/configuracoes/sistema/pesquisa" component={Pesquisa} />
                <AuthenticatedRoute exact path="/configuracoes/sistema/fincategoria" component={FinCategoria} />
            </Layout>
        </Switch>
    </HashRouter>
);

export default Routes;