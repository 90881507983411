import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import { Link, Redirect  } from 'react-router-dom';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Anuidade = () => {
  const { addToast } = useToasts();
  const [ anuidades, setAnuidades ] = useState([]);
  const [ cadastro, setCadastro ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ modalPagamento, setModalPagamento ] = useState(false);
  const [ formAnuidade, setFormAnuidade ] = useState({});
  const [ anoSelect, setAnoSelect ] = useState([]);
  const [ dataCheckIn, setDataCheckIn ] = useState();
  const [ dataVencimento, setDataVencimento ] = useState();
  const [ redirecionar, setRedirecionar ] = useState(false);

  function fecharModal(){
    setModal(false);
  }

  function fecharModalPagamento(){
    setModalPagamento(false);
  }

  const data = anuidades;
  const columns = [
    {
      name: <th>Ano</th>,
      selector: 'ano',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Valor</th>,
      selector: 'valor',
      width: '10%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Data Vencimento</th>,
      selector: 'dtVencimentoF',
      width: '18%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Valor Pago</th>,
      selector: 'valorPago',
      width: '10%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Data Pagamento</th>,
      width: '13%',
      sortable: true,
      selector: 'dtPagamentoF',
      center: 'true'
    },
    {
      name: <th>Pagamento</th>,
      width: '13%',
      sortable: true,
      selector: 'pagoPor',
      center: 'true'
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'pago',
      center: 'true',
      cell: row => { if (row.pago === "S") {return <span class="label label-success label-inline mr-2">pago</span>}
        else if (row.pago === "N") {return <span class="label label-warning label-inline mr-2">Pendente</span>}
        else {return <span class="label label-danger label-inline mr-2">Cancelado</span>}
      }
    },
    {
      name: <th>Ações</th>,
      cell: row => { if (row.pago === "S") 
        { return  <>
            <button className="btn btn-danger btn-sm" onClick={e => { cancelar(row) }}><i className="fas fa-times" />Cancelar</button>
        </>
        } else if (row.pago === "N") { return  <>
          <button className="btn btn-warning btn-sm mr-2" onClick={e => { editar(row) }}><i className="fas fa-pencil-alt" />Alterar</button>
          <button className="btn btn-primary btn-sm" onClick={e => { pagar(row) }}><i className="fas fa-cash-register" />Pagar</button>
        </>
        }
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 

    var data = {
      id: sessionStorage.getItem('cadastro')
    }

    setCaProcessa(true); 
    Api.post("cadastrosanuidade/listar", data).then(rps => {
      
      setAnuidades(rps.data.obj);
      setCadastro(rps.data.cad);
      setCaProcessa(false);
      })
    }

  function botaoVoltar() {    
    setRedirecionar(true);
  }

  if (redirecionar === true) {
    if (sessionStorage.getItem('voltar') == 'cadastro') {
      sessionStorage.setItem('voltar','voltou');
      return <Redirect to="/sistema/cadastro" />
    } 
  } 
  
  function adicionar(){
    setFormAnuidade({});
    getAnoSelect();
    setDataCheckIn('');
    setModal(true);
  }

  function editar(id){  
    var ca = JSON.parse(JSON.stringify(id));

    getAnoSelect();
    setFormAnuidade(ca);
    setDataCheckIn(new Date(ca.dtVencimento + ' 12:00:00'));
    setModal(true);            
  }

  function cancelar(id){
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja cancelar o pagamento?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('cadastrosanuidade/cancelar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    }) 
  }

  function pagar(id) {
    var ca = JSON.parse(JSON.stringify(id));

    getAnoSelect();
    setFormAnuidade(ca);
    setDataCheckIn(new Date(ca.dtVencimento + ' 12:00:00'));
    setModalPagamento(true);
  }

  function salvar(){
    setCaProcessa(true);
    formAnuidade.idCadastro = sessionStorage.getItem('cadastro');
    formAnuidade.dtVencimento = dataCheckIn;

    Api.post('cadastrosanuidade/adicionar', formAnuidade).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormAnuidade({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  } 

  function salvarPagamento(){
    setCaProcessa(true);
    
    Api.post('cadastrosanuidade/pagamento', formAnuidade).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModalPagamento(false);
        setFormAnuidade({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function getAnoSelect() {
    Api.get("ano/listarAtivo").then(rps => {    
      setAnoSelect(rps.data.obj);
    })
  }
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Cadastro</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Anuidades</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-md btn-secondary mt-6 mr-3" onClick={e => {botaoVoltar()}} ><i className="fas fa-arrow-left"/>Voltar</button>
              <button className="btn btn-md btn-success mt-6" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="card-header border-0 bg-primary">
								<div className="card-title">
                  <span className="card-icon">
                    <i className="fas fa-user text-white"></i>
                  </span>
                  <h3 className="card-label text-white">Sócio</h3>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 text-right">
                    <b>Nome</b><br />
                    <b>Tipo de sócio</b><br />
                    <b>Regional</b><br />
                  </div>
                  <div className="col-md-6">
                    {cadastro.dp_nome_rsociao}<br />
                    {cadastro.tipo_cadastro}<br />
                    {cadastro.regional}
                  </div>
                  <div className="col-md-2 text-right">
                    <b>Tipo de Pessoa</b><br />
                    <b>Area de atuação</b><br />
                    <b>Última anuidade</b><br />
                  </div>
                  <div className="col-md-2">
                    {cadastro.ts_nome}<br />
                    {cadastro.aa_nome}<br />
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />

            <div className="card card-custom">
              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Anuidades"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Anuidade</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Ano</label>
          <select className="form-control" value={formAnuidade?.ano} onChange={(e)=> {setFormAnuidade({...formAnuidade, ano: e.target.value}); }}>
                  <option value="">Selecione...</option>
                  {anoSelect.map((item) =>{ return(
                  <option value={item.id}>{item.id}</option>
              )})}
              </select>
        </div>
        
        <div className="form-group col-md-3">
          <label>Data Vencimento</label>
          <DatePicker 
            name="dt_inicio"
            onChange={setDataCheckIn}
            isClearable
            selectsStart
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={dataCheckIn}
            dateFormat="dd/MM/y"
            customInput={
              <InputMask mask="99/99/9999" />}/>
        </div>

        <div className="form-group col-md-3">
          <label>R$ Valor</label>
          <input type="text" value={formAnuidade?.valor} onChange={e => {setFormAnuidade({...formAnuidade, valor: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Após Vencimento R$ Valor</label>
          <input type="text" value={formAnuidade?.valorAposVencimento} onChange={e => {setFormAnuidade({...formAnuidade, valorAposVencimento: e.target.value})}} className="form-control" />
        </div>
      
      </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>


    <Modal size={'xl'} show={modalPagamento} onHide={() => fecharModalPagamento()}>
      <Modal.Header>
      <Modal.Title>Anuidade - Pagamento</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Ano</label>
          <select className="form-control" disabled value={formAnuidade?.ano} onChange={(e)=> {setFormAnuidade({...formAnuidade, ano: e.target.value}); }}>
                  <option value="">Selecione...</option>
                  {anoSelect.map((item) =>{ return(
                  <option value={item.id}>{item.id}</option>
              )})}
              </select>
        </div>
        
        <div className="form-group col-md-3">
          <label>Data Vencimento</label>
          <DatePicker 
            name="dt_inicio"
            onChange={setDataCheckIn}
            isClearable
            selectsStart
            disabled
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa" 
            selected ={dataCheckIn}
            dateFormat="dd/MM/y"
            customInput={
              <InputMask mask="99/99/9999" />}/>
        </div>

        <div className="form-group col-md-3">
          <label>R$ Valor</label>
          <input type="text" disabled value={formAnuidade?.valor} onChange={e => {setFormAnuidade({...formAnuidade, valor: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col-md-3">
          <label>Após Vencimento R$ Valor</label>
          <input type="text" disabled value={formAnuidade?.valorAposVencimento} onChange={e => {setFormAnuidade({...formAnuidade, valorAposVencimento: e.target.value})}} className="form-control" />
        </div>
      </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-3">
            <label>R$ Valor</label>
            <input type="text" value={formAnuidade?.valorPago} onChange={e => {setFormAnuidade({...formAnuidade, valorPago: e.target.value})}} className="form-control" />
          </div>

          <div className="form-group col-md-3">
            <label>Data Pagamento</label>
            <DatePicker 
              name="dt_inicio"
              onChange={(e) => { setFormAnuidade({...formAnuidade, dtPagamento: e}) }}
              isClearable
              selectsStart
              locale="pt-BR"
              className="form-control"
              placeholderText="dd/mm/aaaa" 
              selected ={formAnuidade.dtPagamento ? formAnuidade.dtPagamento : ''}
              dateFormat="dd/MM/y"
              customInput={
                <InputMask mask="99/99/9999" />}/>
          </div>

          <div className="form-group col-md-3"> 
            <label>Pagamento por</label>
            <select className="form-control" value={formAnuidade?.pagoPor} onChange={e => {setFormAnuidade({...formAnuidade, pagoPor: e.target.value})}}>
              <option value="" selected>Selecione</option>    
              <option value="Boleto">Boleto</option>    
              <option value="Depósito">Depósito</option>    
              <option value="Recibo">Recibo</option>    
              <option value="Pago na Regional">Pago na Regional</option>    
              <option value="Outros">Outros</option>    
            </select>
          </div>       
      
        </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModalPagamento(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvarPagamento(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Anuidade);                  